import React, { useRef } from "react"
import PropTypes from "prop-types"

import useIntersectionObserver from "@src/hooks/useIntersectionObserver"

import AnchorLinkTarget from "@src/components/AnchorLinkTarget"

const StickyInlineNavAnchor = ({ children, target, onVisibilityChange }) => {
  const ref = useRef(null)

  const onObserverUpdate =
    onVisibilityChange &&
    (entry => {
      onVisibilityChange(!!entry?.isIntersecting)
    })

  useIntersectionObserver(ref, {
    onUpdate: onObserverUpdate,
    rootMargin: "0px 0px -50% 0px",
  })

  return (
    <AnchorLinkTarget ref={ref} target={target}>
      {children}
    </AnchorLinkTarget>
  )
}

StickyInlineNavAnchor.propTypes = {
  children: PropTypes.node.isRequired,
  target: PropTypes.string.isRequired,
  onVisibilityChange: PropTypes.func,
}

StickyInlineNavAnchor.defaultProps = {
  onVisibilityChange: null,
}

export default StickyInlineNavAnchor
