import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./Sticky.module.less"

const Sticky = ({ children, hideOnWide }) => (
  <div
    className={classNames(styles.sticky, {
      [styles.hideOnWide]: hideOnWide, // TODO: Abstract hide* on utility for this pattern
    })}
  >
    {children}
  </div>
)

Sticky.propTypes = {
  children: PropTypes.node.isRequired,
  hideOnWide: PropTypes.bool,
}

Sticky.defaultProps = {
  hideOnWide: false,
}

export default Sticky
