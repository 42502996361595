import React, { useState } from "react"
import PropTypes from "prop-types"

import { graphql } from "gatsby"

import { ContentImpression } from "@src/lib/impression"

import FaqSection from "@src/components/FaqSection"
import StructuredContentRenderer from "@src/components/StructuredContentRenderer"
import Page, { Footer, Header, HeaderContent } from "@src/components/Page"
import RecapSection from "@src/components/roundup/RecapSection"
import AuthorsSection from "@src/components/AuthorsSection"

import Box from "@src/components/Box"
import { CenteredContainer } from "@nerdwallet/react-layout"
import DateFormat from "@src/components/DateFormat"
import Type from "@nerdwallet/react-typography"

import CreditCardProductSection from "@src/components/roundup/CreditCardProductSection"
import PromotedCreditCardsSection from "@src/components/roundup/PromotedCreditCardsSection"
import CreditCardSummaryTableSection from "@src/components/CreditCardSummaryTableSection"
import Methodology from "@src/components/roundup/Methodology"
import ExpandableContent from "@src/components/ExpandableContent"
import NoOp from "@src/components/NoOp"
import StickyInlineNav, {
  StickyInlineNavAnchor,
} from "@src/components/StickyInlineNav"
import TiledProductCards from "@src/components/TiledProductCards"
import { assignments } from "@src/lib/variations"
import { LocationVariantProvider } from "@src/hooks/useLocationVariant"
import { useAnalyticsPageTrack } from "@src/hooks/useAnalyticsPageTrack"
import useFeatureFlag from "@src/hooks/useFeatureFlag"
import { getCanonicalLink } from "@src/lib/helpers"

import styles from "./cc-roundup.module.less"
import classNames from "classnames"

const isValidSection = section => !!section.marketplaceEntity
const LINK_TABLE_TO_CARDS = false

const CreditCardRoundUp = ({ data, location }) => {
  const roundup = data.smbCreditCardRoundup

  useAnalyticsPageTrack(roundup.contentId)
  const hideRecapSection = useFeatureFlag("hide-recap-section")
  const addStickyInlineNav = useFeatureFlag("add-sticky-inline-nav")
  const NavAnchorSection = addStickyInlineNav ? StickyInlineNavAnchor : NoOp

  const addPromotedOffer = useFeatureFlag("add-promoted-offer")
  const addTiledProductCards = useFeatureFlag("add-tiled-product-cards")

  const validSections = roundup.roundupSections.filter(isValidSection)

  const [activeNavSection, setActiveNavSection] = useState(null)
  const makeNavSectionUpdateHandler = target => isVisible => {
    if (isVisible) {
      setActiveNavSection(target)
    }
  }
  const navLinks = [
    {
      target: "nav-target-summary-table",
      text: "Compare cards",
    },
    {
      target: "nav-target-product-cards",
      text: "NerdWallet’s picks",
    },
    {
      target: "nav-target-other-resources",
      text: "Learn more",
    },
    ...(roundup.methodology
      ? [
          {
            target: "nav-target-methodology",
            text: "Methodology",
          },
        ]
      : []),
    ...(roundup.faqs ? [{ target: "nav-target-faq", text: "FAQ" }] : []),
  ].map(section => ({
    ...section,
    active: activeNavSection === section.target,
  }))
  const header = <Header attributionTopic={roundup.attributionTopic} />

  const headerContent = (
    <HeaderContent
      title={roundup.title}
      date={roundup.displayDate}
      authors={roundup.authors}
      synopsis={
        <StructuredContentRenderer>
          {roundup.synopsis}
        </StructuredContentRenderer>
      }
      breadcrumb={roundup.breadcrumb}
      featuredImage={roundup.featuredMedia}
      headerSpace={9}
      assigningEditor={roundup.assigningEditor}
      hideAuthorByline={!roundup.showAuthorByline}
      hideEditorialStandards={!roundup.showEditorialDisclaimer}
    />
  )
  const footer = <Footer attributionTopic={roundup.attributionTopic} />

  return (
    <ContentImpression contentId={roundup.contentId}>
      <LocationVariantProvider location={location} assignments={assignments}>
        <Page
          title={roundup.title}
          link={getCanonicalLink(roundup)}
          featuredImage={roundup.featuredMedia}
          seo={roundup.seo}
          jsonld={roundup.jsonld}
          header={header}
          headerContent={headerContent}
          footer={footer}
          contentWrapper={NoOp}
        >
          <main>
            <CenteredContainer>
              <div className={(styles.marginTop, styles.marginBottom)}>
                <ExpandableContent>
                  <StructuredContentRenderer>
                    {roundup.aboveSections}
                  </StructuredContentRenderer>
                </ExpandableContent>
              </div>
            </CenteredContainer>

            {addPromotedOffer && (
              <CenteredContainer>
                <Box marginBottom={5} justifyContent="center">
                  <PromotedCreditCardsSection offers={roundup.promotedOffers} />
                </Box>
              </CenteredContainer>
            )}

            {addStickyInlineNav && <StickyInlineNav links={navLinks} />}

            {addTiledProductCards && (
              <CenteredContainer>
                <Box marginY={5}>
                  <TiledProductCards sections={validSections} />
                </Box>
              </CenteredContainer>
            )}

            <CenteredContainer>
              <NavAnchorSection
                target="nav-target-summary-table"
                onVisibilityChange={makeNavSectionUpdateHandler(
                  "nav-target-summary-table"
                )}
              >
                <div className={classNames(styles.smallSection)}>
                  <CreditCardSummaryTableSection
                    withGoToLink={LINK_TABLE_TO_CARDS}
                    table={roundup.summaryTableSection}
                    productDescription={roundup.seo.keyword}
                  />
                </div>
              </NavAnchorSection>
              <NavAnchorSection
                target="nav-target-product-cards"
                onVisibilityChange={makeNavSectionUpdateHandler(
                  "nav-target-product-cards"
                )}
              >
                <div className={styles.section}>
                  <CreditCardProductSection
                    anchorTargetCards={LINK_TABLE_TO_CARDS}
                    categorySections={roundup.productCardsSection}
                    highlightBestFor
                  />
                </div>
              </NavAnchorSection>

              <NavAnchorSection
                target="nav-target-other-resources"
                onVisibilityChange={makeNavSectionUpdateHandler(
                  "nav-target-other-resources"
                )}
              >
                <div className={styles.bottomParagraphs}>
                  <StructuredContentRenderer>
                    {roundup.belowSections}
                  </StructuredContentRenderer>
                </div>

                <Box className={styles.bottomSection}>
                  <Type inline italic>
                    Last updated on{" "}
                    <DateFormat timestamp={roundup.displayDate} />
                  </Type>
                </Box>

                {!hideRecapSection && (
                  <div className={styles.bottomSection}>
                    <RecapSection
                      title={roundup.title}
                      sections={validSections}
                    />
                  </div>
                )}
              </NavAnchorSection>

              {roundup.methodology && (
                <NavAnchorSection
                  target="nav-target-methodology"
                  onVisibilityChange={makeNavSectionUpdateHandler(
                    "nav-target-methodology"
                  )}
                >
                  <Box className={styles.bottomSection}>
                    <Methodology methodology={roundup.methodology} />
                  </Box>
                </NavAnchorSection>
              )}

              {roundup.faqs && (
                <NavAnchorSection
                  target="nav-target-faq"
                  onVisibilityChange={makeNavSectionUpdateHandler(
                    "nav-target-faq"
                  )}
                >
                  <Box className={styles.faq}>
                    <FaqSection faqs={roundup.faqs} />
                  </Box>
                </NavAnchorSection>
              )}
              <Box className={styles.author}>
                <AuthorsSection authorBoxes={roundup.authorBox} />
              </Box>
            </CenteredContainer>
          </main>
        </Page>
      </LocationVariantProvider>
    </ContentImpression>
  )
}

CreditCardRoundUp.propTypes = {
  data: PropTypes.shape({
    smbCreditCardRoundup: PropTypes.shape({
      contentId: PropTypes.number.isRequired,
      customPath: PropTypes.string,
      breadcrumb: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        page: PropTypes.shape({
          title: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      jsonld: PropTypes.object,
      displayDate: PropTypes.string,
      attributionTopic: PropTypes.string,
      assigningEditor: PropTypes.object,
      authors: PropTypes.array,
      primaryCategory: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      showAuthorByline: PropTypes.bool,
      showEditorialDisclaimer: PropTypes.bool,
      synopsis: PropTypes.any,
      aboveSections: PropTypes.any,
      roundupSections: PropTypes.array.isRequired,
      belowSections: PropTypes.any,
      faqs: PropTypes.array,
      authorBox: PropTypes.array,
      seo: PropTypes.shape({ keyword: PropTypes.string }).isRequired,
      featuredMedia: PropTypes.shape({}).isRequired,
      summaryTableSection: PropTypes.shape({}),
      productCardsSection: PropTypes.arrayOf(PropTypes.shape({})),
      methodology: PropTypes.shape({}),
      promotedOffers: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default CreditCardRoundUp

export const query = graphql`
  query ($slug: String!) {
    smbCreditCardRoundup(slug: { eq: $slug }) {
      contentId
      customPath
      title
      link
      jsonld
      displayDate
      attributionTopic
      assigningEditor {
        id
        name
        link
      }
      authors {
        id
        name
        link
        url: link
      }
      primaryCategory {
        name
      }
      breadcrumb {
        slug
        page {
          title
        }
      }
      showAuthorByline
      showEditorialDisclaimer
      synopsis
      aboveSections
      roundupSections {
        categoryName
        categoryDescription
        marketplaceEntity {
          id
          status
          ... on SmallMediumBusinessLoansOfferV2 {
            prosBullets
            consBullets
            image {
              alt
              sourceMedium
            }
            displayName
            maxLoanAmount
            tagline
            qualificationsBullets
            reviewLink
            institution {
              id
              name
            }
            productType
            placementDrivers {
              id
              label
              value
              tooltip
            }
            productLabel
            product {
              id
            }
          }
          ... on CreditCardsOfferV2 {
            displayName
            reviewLink
            institution {
              name
            }
            product {
              id
            }
            image {
              source
              alt
            }
            monetizable
            cta {
              link
              text
              subtext
              type
            }
          }
        }
      }
      belowSections
      faqs {
        question
        answer
        answerSchema
      }
      authorBox: authors {
        id
        name
        link
        contactEmail
        twitter
        facebook
        linkedin
        marketplaceBio
        description
        avatarUrls {
          size
          url
        }
      }
      featuredMedia {
        croppedImages {
          sourceUrl
          width
          height
        }
      }
      ...SummaryTableSection
      ...ProductCards
      ...PromotedCreditCards
      ...Methodology
      seo {
        title
        description
        keyword
        facebook {
          title
          description
        }
        twitter {
          title
          description
        }
      }
    }
  }
`
