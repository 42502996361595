import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import LikelihoodOfApproval from "@src/components/LikelihoodOfApproval"
import { List, Li } from "@nerdwallet/currency"
import TrackedCTA from "@src/components/TrackedCTA"
import ItemRating from "@src/components/ItemRating"

import { RATES_AND_FEES_CTA_TYPE } from "@src/lib/constants"

import {
  CardHeading,
  CardImageWrapper,
  FeaturedWrapper,
  CardContentWrapper,
  CtaWrapper,
  QuickFacts,
  CardProsCons,
} from "../cardSubComponents"

import styles from "./PromotedCreditCard.module.less"

const PromotedCreditCard = ({ offer }) => {
  const {
    prosBullets,
    consBullets,
    facts,
    placementDrivers,
    imgAlt,
    imgSrc,
    heading,
    starRating,
    reviewUrl,
    cta,
    termsAndFeesLink,
    recommendedCreditScoreRangeDetails,
    marketingBullets,
    minFico,
    maxFico,
    marketplaceEntity,
  } = offer

  const recommendedCreditScoreQuickFact = {
    id: "recommendedCreditScore",
    label: "Recommended Credit Score",
    valueComponent: (
      <div className={styles.likelihoodOfApprovalContainer}>
        <LikelihoodOfApproval
          minFico={minFico}
          maxFico={maxFico}
          scoreRangeNumeric={
            recommendedCreditScoreRangeDetails.creditScoreRange
          }
          fullWidth
          scoreRangeText={recommendedCreditScoreRangeDetails.description}
        />
      </div>
    ),
  }

  const filteredPlacementDrivers = placementDrivers
    .concat(recommendedCreditScoreQuickFact)
    .filter(
      driver => driver.value !== null && facts.some(fact => fact === driver.id)
    )

  const prosCons = (
    <CardProsCons pros={prosBullets} cons={consBullets} reviewUrl={reviewUrl} />
  )

  const detailsComponent = (
    <List>
      {marketingBullets.map(d => {
        return <Li key={d}>{d}</Li>
      })}
      {termsAndFeesLink && (
        <Li>
          <a
            className={styles.viewRatesAndFeesLink}
            href={termsAndFeesLink}
            data-cta-type={RATES_AND_FEES_CTA_TYPE}
          >
            View Rates & Fees
          </a>
        </Li>
      )}
    </List>
  )

  const ctaButton = (
    <>
      {cta?.link && (
        <TrackedCTA
          id={marketplaceEntity.id}
          productType={marketplaceEntity.productType}
          product={marketplaceEntity.product}
          institution={marketplaceEntity.institution}
          monetizable={marketplaceEntity.monetizable}
          cta={cta}
          ctaType="CC Promoted CTA"
          href={cta.link}
          classNames={classNames("clickable", styles.button)}
        />
      )}
      {termsAndFeesLink && (
        <div className={styles.ratesAndFeesLink}>
          <a href={termsAndFeesLink} data-cta-type={RATES_AND_FEES_CTA_TYPE}>
            Rates & Fees
          </a>
        </div>
      )}
    </>
  )

  return (
    <FeaturedWrapper>
      <CardImageWrapper
        imgAlt={imgAlt}
        imgSrc={imgSrc}
        heading={heading}
        withGap
      />
      <CardHeading fullWidthContent heading={heading} narrow>
        {starRating && reviewUrl && (
          <ItemRating
            displayNumber
            horizontal
            href={reviewUrl}
            rating={starRating}
          />
        )}
      </CardHeading>

      <CtaWrapper>{ctaButton}</CtaWrapper>
      <QuickFacts facts={facts} placementDrivers={filteredPlacementDrivers} />
      <CardContentWrapper
        accordion
        prosCons={prosCons}
        detailsComponent={detailsComponent}
        heading={heading}
      />
    </FeaturedWrapper>
  )
}

PromotedCreditCard.propTypes = {
  offer: PropTypes.shape({
    prosBullets: PropTypes.arrayOf(PropTypes.string).isRequired,
    consBullets: PropTypes.arrayOf(PropTypes.string).isRequired,
    facts: PropTypes.arrayOf(PropTypes.string).isRequired,
    placementDrivers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        tooltip: PropTypes.any,
        description: PropTypes.string,
      })
    ),
    imgAlt: PropTypes.string.isRequired,
    imgSrc: PropTypes.string.isRequired,
    heading: PropTypes.node.isRequired,
    starRating: PropTypes.string.isRequired,
    bottomLine: PropTypes.node.isRequired,
    productDetails: PropTypes.arrayOf(PropTypes.string).isRequired,
    reviewUrl: PropTypes.string,
    marketingBullets: PropTypes.arrayOf(PropTypes.string).isRequired,
    productLabel: PropTypes.string,
    cta: PropTypes.shape({
      link: PropTypes.string.isRequired,
      subtext: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      type: PropTypes.string,
    }).isRequired,
    termsAndFeesLink: PropTypes.string,
    recommendedCreditScoreRangeDetails: PropTypes.shape({
      description: PropTypes.string.isRequired,
      creditScoreRange: PropTypes.string.isRequired,
    }).isRequired,
    maxFico: PropTypes.number.isRequired,
    minFico: PropTypes.number.isRequired,
    marketplaceEntity: PropTypes.shape({
      id: PropTypes.string,
      displayName: PropTypes.string,
      productType: PropTypes.string,
      product: PropTypes.shape({
        id: PropTypes.string,
      }),
      institution: PropTypes.shape({
        id: PropTypes.string,
      }),
      monetizable: PropTypes.string,
    }),
  }).isRequired,
}

export default PromotedCreditCard
