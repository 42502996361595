import React from "react"
import PropTypes from "prop-types"

import { Accent } from "@nerdwallet/currency"
import Type from "@nerdwallet/react-typography"

import Sticky from "@src/components/Sticky"
import HighlightedList, {
  HighlightedItem,
} from "@src/components/HighlightedList"
import ExpandablePanel from "@src/components/ExpandablePanel"

import styles from "./StickyInlineNav.module.less"

const Nav = ({ heading, children }) => {
  const accentHeading = (
    <div className={styles.heading}>
      <Accent>{heading}</Accent>
    </div>
  )
  return (
    <ExpandablePanel
      heading={accentHeading}
      dataAttributes={{
        "nw-nav-type": "Sticky Inline Nav",
        "nw-mp": true,
      }}
      narrow
      sticky
    >
      {children}
    </ExpandablePanel>
  )
}

Nav.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.string,
}

const StickyInlineNav = ({ links, heading }) => {
  return (
    <Sticky hideOnWide>
      <Nav heading={heading}>
        <HighlightedList className={styles.list}>
          {links.map(({ target, text, active }) => (
            <HighlightedItem
              key={target}
              target={target}
              active={active}
              data-nw-nav-link={target}
              data-nw-mp
              strong
              styleColor="Yellowish"
            >
              <Type className={styles.item} inline>
                {text}
              </Type>
            </HighlightedItem>
          ))}
        </HighlightedList>
      </Nav>
    </Sticky>
  )
}

StickyInlineNav.propTypes = {
  heading: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      target: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
}

StickyInlineNav.defaultProps = {
  heading: "Table of contents",
}

export default StickyInlineNav
