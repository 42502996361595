import React from "react"
import PropTypes from "prop-types"

import { graphql } from "gatsby"
import { Title } from "@nerdwallet/react-typography"

import StructuredContentRenderer from "@src/components/StructuredContentRenderer"

const Methodology = ({ methodology }) => {
  return (
    <>
      <Title component="h2" size="medium">
        Methodology
      </Title>
      <StructuredContentRenderer>{methodology}</StructuredContentRenderer>
    </>
  )
}

Methodology.propTypes = {
  methodology: PropTypes.shape({}),
}

export const query = graphql`
  fragment Methodology on smbCreditCardRoundup {
    methodology
  }
`

export default Methodology
