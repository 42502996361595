import React from "react"
import PropTypes from "prop-types"

import { graphql } from "gatsby"

import PromotedCreditCard from "@src/components/ProductCard/PromotedCreditCard"
import { getApplyOrRedirectUrl } from "@src/lib/helpers"

const FACTS = [
  "annualFee",
  "rewardRate",
  "introOffer",
  "recommendedCreditScore",
]

const mapSectionEntryToProductCard = marketplaceEntity => {
  const {
    placementDrivers,
    starRating,
    marketingBullets,
    maxFico,
    minFico,
    cta,
    productLabel,
    termsAndFeesLink,
    prosBullets,
    consBullets,
    recommendedCreditScoreRangeDetails,
  } = marketplaceEntity
  return {
    prosBullets,
    consBullets,
    facts: FACTS,
    placementDrivers,
    starRating,
    imgAlt: marketplaceEntity.image.alt,
    imgSrc: marketplaceEntity.image.source,
    heading: marketplaceEntity.displayName,
    reviewUrl: marketplaceEntity.reviewLink,
    termsAndFeesLink,
    recommendedCreditScoreRangeDetails,
    marketingBullets,
    maxFico,
    minFico,
    cta,
    applyUrl: getApplyOrRedirectUrl({ marketplaceEntity }, process.env),
    type: marketplaceEntity.productType,
    productLabel,
    marketplaceEntity: {
      id: marketplaceEntity.id,
      product: marketplaceEntity.product,
      displayName: marketplaceEntity.displayName,
      institution: marketplaceEntity.institution,
      productType: marketplaceEntity.productType,
      monetizable: marketplaceEntity.monetizable,
    },
  }
}

const PromotedCreditCardsSection = ({ offers }) => {
  return <PromotedCreditCard offer={mapSectionEntryToProductCard(offers[0])} />
}

PromotedCreditCardsSection.propTypes = {
  offers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export const query = graphql`
  fragment PromotedCreditCards on smbCreditCardRoundup {
    promotedOffers {
      id
      status
      productType
      ... on CreditCardsOfferV2 {
        recommendedCreditScoreRangeDetails {
          creditScoreRange
          description
        }
        prosBullets
        consBullets
        marketingBullets
        maxFico
        minFico
        name
        displayName
        image {
          source
          alt
        }
        annualFee
        starRating
        monetizable
        institution {
          id
          name
        }
        product {
          id
        }
        reviewLink
        termsAndFeesLink
        placementDrivers {
          id
          label
          value
          tooltip
          description
        }
        cta {
          link
          text
          subtext
          type
        }
      }
    }
  }
`

export default PromotedCreditCardsSection
