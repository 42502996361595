import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./ExpandablePanel.module.less"

const ExpandablePanel = ({
  children,
  dataAttributes,
  initialStateExpanded,
  heading,
  sticky,
  narrow,
}) => {
  const dataAttributeProps = Object.keys(dataAttributes || {}).reduce(
    (acc, key) => {
      acc[`data-${key}`] = dataAttributes[key]
      return acc
    },
    {}
  )
  return (
    <details
      className={classNames(styles.details, {
        [styles.sticky]: sticky,
      })}
      open={initialStateExpanded || null}
    >
      <summary
        className={classNames(styles.summary, "clickable", {
          [styles.narrow]: narrow,
        })}
        {...dataAttributeProps}
      >
        {heading}
      </summary>
      <div
        className={classNames(styles.content, {
          [styles.narrowContent]: narrow,
        })}
      >
        {children}
      </div>
    </details>
  )
}

ExpandablePanel.propTypes = {
  heading: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  dataAttributes: PropTypes.objectOf(PropTypes.any),
  initialStateExpanded: PropTypes.bool,
  narrow: PropTypes.bool,
  sticky: PropTypes.bool,
}

ExpandablePanel.defaultProps = {
  initialStateExpanded: false,
  narrow: false,
  sticky: false,
}

export default ExpandablePanel
