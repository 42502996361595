import React from "react"
import PropTypes from "prop-types"

import { graphql } from "gatsby"

import Category from "@src/components/roundup/Category"
import { getApplyOrRedirectUrl, isSectionActive } from "@src/lib/helpers"
import CreditCardProductCard from "@src/components/ProductCard/CreditCardProductCard"

const FACTS = [
  "annualFee",
  "rewardRate",
  "introOffer",
  "recommendedCreditScore",
]

const MarketplaceProduct = ({
  section,
  disableBorder,
  anchorTargetCards,
  showEmailButton,
}) => {
  const { benefits, drawbacks, bottomLine, marketplaceEntity } = section
  const {
    placementDrivers,
    starRating,
    marketingBullets,
    maxFico,
    minFico,
    cta,
    productLabel,
    termsAndFeesLink,
    prosBullets,
    consBullets,
    recommendedCreditScoreRangeDetails,
  } = marketplaceEntity

  return (
    <CreditCardProductCard
      disableBorder={disableBorder}
      anchorTargetCards={anchorTargetCards}
      showEmailButton={showEmailButton}
      columnedOnMobileOnly
      creditCard={{
        benefits,
        drawbacks,
        prosBullets,
        consBullets,
        bottomLine,
        facts: FACTS,
        placementDrivers,
        starRating,
        imgAlt: marketplaceEntity.image.alt,
        imgSrc: marketplaceEntity.image.source,
        heading: marketplaceEntity.displayName,
        reviewUrl: marketplaceEntity.reviewLink,
        termsAndFeesLink,
        recommendedCreditScoreRangeDetails,
        marketingBullets,
        maxFico,
        minFico,
        cta,
        applyUrl: getApplyOrRedirectUrl(section, process.env),
        type: marketplaceEntity.productType,
        productLabel,
        marketplaceEntity: {
          id: marketplaceEntity.id,
          product: marketplaceEntity.product,
          displayName: marketplaceEntity.displayName,
          institution: marketplaceEntity.institution,
          productType: marketplaceEntity.productType,
          monetizable: marketplaceEntity.monetizable,
        },
      }}
    />
  )
}

MarketplaceProduct.propTypes = {
  disableBorder: PropTypes.bool,
  anchorTargetCards: PropTypes.bool,
  showEmailButton: PropTypes.bool,
  section: PropTypes.shape({
    benefits: PropTypes.shape({}),
    drawbacks: PropTypes.shape({}),
    bottomLine: PropTypes.node,
    marketplaceEntity: PropTypes.shape({
      prosBullets: PropTypes.arrayOf(PropTypes.string).isRequired,
      consBullets: PropTypes.arrayOf(PropTypes.string).isRequired,
      marketingBullets: PropTypes.arrayOf(PropTypes.string).isRequired,
      starRating: PropTypes.string.isRequired,
      image: PropTypes.shape({
        alt: PropTypes.string.isRequired,
        source: PropTypes.string.isRequired,
      }).isRequired,
      displayName: PropTypes.any.isRequired,
      tagline: PropTypes.any.isRequired,
      qualificationsBullets: PropTypes.any.isRequired,
      reviewLink: PropTypes.any.isRequired,
      termsAndFeesLink: PropTypes.any.isRequired,
      monetizable: PropTypes.string,
      cta: PropTypes.shape({
        link: PropTypes.string.isRequired,
      }).isRequired,
      maxFico: PropTypes.number,
      minFico: PropTypes.number,
      maxLoanAmount: PropTypes.number,
      recommendedCreditScoreRangeDetails: PropTypes.any.isRequired,
      productType: PropTypes.any.isRequired,
      placementDrivers: PropTypes.any.isRequired,
      productLabel: PropTypes.string,
      id: PropTypes.string,
      product: PropTypes.shape({
        id: PropTypes.string,
      }),
      institution: PropTypes.shape({
        id: PropTypes.string,
      }),
    }).isRequired,
  }).isRequired,
}

MarketplaceProduct.defaultProps = {
  disableBorder: false,
  anchorTargetCards: false,
  showEmailButton: false,
}

const CreditCardProductSection = props => {
  const {
    categorySections: { categorySections },
    highlightBestFor,
    anchorTargetCards,
    showEmailButton,
  } = props

  return (
    <section>
      {categorySections.map(categorySection => {
        const roundUpSectionsForCategory = categorySection.productCards.filter(
          section => isSectionActive(section)
        )
        return (
          <Category
            key={categorySection.title}
            category={categorySection.title}
            sections={roundUpSectionsForCategory}
            marketplaceProductComponent={MarketplaceProduct}
            highlightBestFor={highlightBestFor}
            anchorTargetCards={anchorTargetCards}
            showEmailButton={showEmailButton}
          />
        )
      })}
    </section>
  )
}

CreditCardProductSection.propTypes = {
  highlightBestFor: PropTypes.bool,
  anchorTargetCards: PropTypes.bool,
  showEmailButton: PropTypes.bool,
  categorySections: PropTypes.shape({
    categorySections: PropTypes.arrayOf(
      PropTypes.shape({
        categoryName: PropTypes.string,
        marketplaceEntity: PropTypes.shape({
          status: PropTypes.string.isRequired, // used by isSectionActive
        }).isRequired,
      })
    ).isRequired,
  }).isRequired,
}

CreditCardProductSection.defaultProps = {
  highlightBestFor: false,
  anchorTargetCards: false,
  showEmailButton: false,
}

export const query = graphql`
  fragment ProductCards on smbCreditCardRoundup {
    productCardsSection {
      categorySections {
        title
        productCards {
          bottomLine
          benefits
          drawbacks
          customReviewUrl
          driverGroup
          excludeModelExecutionIds
          productCardComponent
          productType

          marketplaceEntity {
            id
            status
            productType
            ... on CreditCardsOfferV2 {
              recommendedCreditScoreRangeDetails {
                creditScoreRange
                description
              }
              prosBullets
              consBullets
              marketingBullets
              maxFico
              minFico
              name
              displayName
              image {
                source
                alt
              }
              annualFee
              starRating
              monetizable
              institution {
                id
                name
              }
              product {
                id
              }
              reviewLink
              termsAndFeesLink
              placementDrivers {
                id
                label
                value
                tooltip
                description
              }
              cta {
                link
                text
                subtext
                type
              }
            }
          }

          shouldEnableAddToCompare
          shouldEnableImageLazyLoad
        }
      }
    }
  }
`

export default CreditCardProductSection
